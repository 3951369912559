import React from 'react'
import './category-article.scss'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import largeArrow from "../../assets/arrow-large-2.webp";

const CategoryArticle = ({ title, excerpt, CTAText, CTAUrl, image }) => {

  const markdown = excerpt?.substring(0, 30) || ''


  return (
    <article className='category-article'>
      {
        image !== undefined ?
          <GatsbyImage image={image} alt=""></GatsbyImage>
          :
          <img src={largeArrow} className="undefined-case-image" alt="page logo"></img>
      }


      <p className='article-heading'>
        {
          CTAUrl !== "" ?
            <Link to={CTAUrl}>{CTAText}</Link>
            :
            <h3>{CTAText}</h3>
        }
      </p>


      {title.length > 42 ? '' :
        <div className='markdown-container'>
          {excerpt ? <>
            <p>
              <ReactMarkdown children={`${markdown}...`} className='markdown_wrapper' />
            </p>
            {CTAUrl !== "" ?
              <Link className='read-mode-blog-article' to={CTAUrl}> Read more</Link>
              : ""}
          </>
            : ''}
        </div>
      }

    </article>
  )
}

export default CategoryArticle